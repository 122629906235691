import React, { Component } from 'react';
import {Link} from 'react-router-dom';

import {TextInput} from "./TextInput";
import {MoonCard} from "./MoonCard";
import {getWrapperURL, WRAPPER_ADDRESS} from '../contracts/LunarToken';

export class DetailView extends Component {
  Modes = {
    View: "VIEW",
    Purchase: "PURCHASE",
    Transfer: "TRANSFER",
    Edit: "EDIT",
    Wrap: "WRAP",
    Unwrap: "UNWRAP",
  };

  constructor(props) {
    super(props);
    this.state = {mode: this.Modes.View};
  }

  componentDidMount() {
    if (this.props.moon) {
      this.props.moon.enableInteraction();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.id && nextProps.id != this.props.id) {
      this.setState({mode: this.Modes.View});
    }

    if (nextProps.moon) {
      nextProps.moon.enableInteraction();
    }
  }

  onView = () => {
    this.setState({mode: this.Modes.View});
  }

  onPurchase = () => {
    this.props.connect();
    this.setState({mode: this.Modes.Purchase});
  }

  onEdit = () => {
    this.setState({mode: this.Modes.Edit});
  }

  onTransfer = () => {
    this.setState({mode: this.Modes.Transfer});
  }

  onClickWrapper = () => {
    window.open(getWrapperURL(this.props.id));
  }

  onWrap = () => {
    this.setState({mode: this.Modes.Wrap});
  }

  getViewContent = () => {
    const {id, user, plot, pendingPurchases} = this.props;

    if (plot.owner === user) {
      // Should allow user to edit or transfer plot
      if (plot.isWrapped) {
        return (
          <div className="lunar__cta-row">
            <button className="lunar__cta" onClick={this.onClickWrapper}>View</button>
            <button className="lunar__cta" onClick={this.props.onUnwrap}>Unwrap</button>
          </div>
        );
      }
      return (
        <div className="lunar__cta-row">
          <button className="lunar__cta" onClick={this.onEdit}>Edit</button>
          <button className="lunar__cta" onClick={this.onTransfer}>Transfer</button>
          <button className="lunar__cta" onClick={this.onWrap}>Wrap</button>
        </div>
      );
    } else if (pendingPurchases && pendingPurchases[id]) {
      return (
        <button className="lunar__cta" disabled>
          Pending...
        </button>
      );
    } else if (!plot.owned || plot.forSale) {
      // Show Purchase button
      return (
        <button className="lunar__cta" onClick={this.onPurchase}>
          Purchase
        </button>
      );
    } else if (plot.isWrapped) {
      return (
        <button className="lunar__cta" onClick={this.onClickWrapper}>
          View on OpenSea
        </button>
      );
    } else {
      return (
        <button className="lunar__cta" disabled>Not for Sale</button>
      );
    }
  }

  getPurchaseContent = () => {
    const {onUpdate, onSubmit, plot, price, priceInUSD, purchaseSubmitted} = this.props;

    return (
      <div className="lunar__form">
        <div className="lunar__form__prices">
          <TextInput name="price"
            label={<span>Set your new sell price</span>}
            onChange={onUpdate}
            value={price}
            placeholder={plot.price ? 2 * plot.price : "0.05"}
            suffix="ETH"
            disabled={purchaseSubmitted}
            rightAligned={true}
            min="0"
            step=".1"
            type="number"
          />
          <TextInput name="priceInUSD"
            value={priceInUSD}
            rightAligned={true}
            suffix="USD"
            disabled
          />
        </div>
        <button className="lunar__cta" onClick={onSubmit} disabled={purchaseSubmitted}>
          Confirm{purchaseSubmitted && <div className="lunar__loader"></div>}
        </button>
      </div>
    );
  }

  getTransferContent = () => {
    const {onUpdate, onTransfer, address, transferSubmitted, validAddress} = this.props;
    console.log(validAddress);
    return (
      <div className="lunar__form">
        <div className="lunar__form__transfer">
          <TextInput name="address"
            label={<span>Send to address</span>}
            onChange={onUpdate}
            value={address}
            placeholder={"0x0000000000000000000000000000000000000000"}
            disabled={transferSubmitted}
          />
        </div>
        <button className="lunar__cta" onClick={onTransfer} disabled={!validAddress || transferSubmitted}>
          Transfer{transferSubmitted && <div className="lunar__loader"></div>}
        </button>
      </div>
    );
  }

  getEditContent = () => {
    const {
      onUpdate,
      onSetPrice,
      price,
      editSubmitted,
      plot,
      priceInUSD,
      onSetMetadata,
      metadata,
      metadataSubmitted,
    } = this.props;

    return (
      <div className="lunar__form">
        <div className="lunar__form__prices">
          <TextInput name="price"
            label="New sell price"
            onChange={onUpdate}
            value={price}
            placeholder={plot.price ? plot.price : "0.05"}
            suffix="ETH"
            disabled={editSubmitted}
            rightAligned={true}
            min="0"
            step=".1"
            type="number"
          />
          <TextInput name="priceInUSD"
            value={priceInUSD}
            rightAligned={true}
            suffix="USD"
            disabled
          />
        </div>
        <button className="lunar__cta" onClick={onSetPrice} disabled={editSubmitted}>
          Update Price{editSubmitted && <div className="lunar__loader"></div>}
        </button>
        <TextInput name="metadata"
          value={metadata}
          disabled={metadataSubmitted}
          label="Metadata"
          maxLength="100"
          onChange={onUpdate}
          placeholder="Description or metadata link"
        />
        <button className="lunar__cta" onClick={onSetMetadata} disabled={metadataSubmitted}>
          Update Metadata{metadataSubmitted && <div className="lunar__loader"></div>}
        </button>
      </div>
    );
  }

  getWrapContent = () => {
    const {onWrap, wrapSubmitted} = this.props;
    return (
      <div className="lunar__form">
        <div className="lunar__form__transfer">
          <span>Wrapping your plot will transfer it to a wrapper contract. You'll have to confirm three separate transactions.</span>
        </div>
        <button className="lunar__cta" onClick={onWrap} disabled={wrapSubmitted}>
          Confirm Wrap{wrapSubmitted && <div className="lunar__loader"></div>}
        </button>
      </div>
    );
  }

  getContent = () => {
    const {View, Purchase, Transfer, Edit, Wrap} = this.Modes;
    switch(this.state.mode) {
      case Purchase:
        return this.getPurchaseContent();
      case Transfer:
        return this.getTransferContent();
      case Wrap:
        return this.getWrapContent();
      case Edit:
        return this.getEditContent();
      case View:
      default:
        return this.getViewContent();
    }
  }

  render() {
    const {id, plot, user} = this.props;

    return (
      <div className="lunar__detail">
        <Link to="/profile" className="lunar__detail__back">Back</Link>
        <MoonCard
          id={id}
          title={"Plot " + id}
          plot={plot}
          isOwner={user === plot.owner}
          isWrapped={plot.isWrapped || plot.owner === WRAPPER_ADDRESS}
          description={null}
          key="detail_active_moon_card"
        />
        {this.getContent()}
      </div>
    );
  }
}
