import * as Web3 from "web3";
import {abi as LunarABI} from "./LunarToken.json";
import {abi as WrapperABI} from "./WrappedLunar.json";

const ETHERSCAN_API_KEY = 'UXM7G3QA1GV9D95JPAG9YCU8W4HEZ75WEC';
const ETH_USD_API = `https://api.etherscan.io/api?module=stats&action=ethprice&apikey=${ETHERSCAN_API_KEY}`;
const API_DEBOUNCE_MS = 10000;
const NUM_PLOTS = 400;
let web3;

export const WRAPPER_ADDRESS = '0xE81175546F554ca6cEB63b142f27de7557C5Bf62';
export const getWrapperURL = (id) => `https://opensea.io/assets/${WRAPPER_ADDRESS}/${id}`;

export class Web3LoadingError extends Error {}
export class EthereumError extends Error {}
export class PurchaseError extends Error {}
export class TransferError extends Error {}

export class LunarToken {
  contract;
  initialPrice;
  plots;
  usdConversion;
  conversionLastFetched;
  totalOwned;
  onGetPlots;

  constructor(onGetPlots) {
    web3 = window.web3;
    this.plots = {};
    this.onGetPlots = onGetPlots;
  }

  initialize = async () => {
    web3 = window.web3;
    try {
      const provider = await this.getProvider();
      web3 = new Web3(provider);
      this.contract = new web3.eth.Contract(LunarABI, process.env.REACT_APP_CONTRACT_ADDRESS, {gas: 200000});
      this.wrapper = new web3.eth.Contract(WrapperABI, WRAPPER_ADDRESS, {gas: 200000});
      this.getBasicData(); // This is not awaited
    } catch(e) {
      if (e instanceof Web3LoadingError) {
        throw e;
      } else {
        throw new EthereumError("Unable to initialize web3 and contract:", e.message);
      }
    }
  }

  enableConnection = async () => {
    await window.ethereum.enable();
  }

  /** Retries getting provider since web3 may take a while to be injected */
  getProvider = async (maxRetries=5, retries=0) => {
    const _getProvider = () => {
      if (typeof window.web3 !== 'undefined' && window.web3.currentProvider) {
        return window.web3.currentProvider;
      } else {
        return false;
      }
    }

    if (retries < maxRetries) {
      if (retries > 0) {
        console.log("Attempting to reach provider. Retry:", retries)
      }

      const provider = _getProvider();

      if (provider) {
        return provider;
      } else {
        // Retry after 200ms
        await new Promise(resolve => setTimeout(resolve, 300));
        return await this.getProvider(maxRetries, retries+1);
      }
    } else {
      throw new Web3LoadingError("Could not load web3 provider after " + retries + " retries");
    }
  }

  /** Fetches commonly used data from the contract */
  getBasicData = async () => {
    this.initialPrice = await this.contract.methods.initialPrice().call();
    this.totalOwned = await this.contract.methods.totalOwned().call();
    this.tradingEnabled = await this.contract.methods.tradingEnabled().call();
    this.getETHConversion(1);

    // Load plots from main contract
    let plotPromises = [];
    for (let i = 0; i < NUM_PLOTS; i++) {
      plotPromises.push(this.getPlot(i))
    }
    await Promise.all(plotPromises);

    // Load wrapper plots
    // this.wrappedPlots = await this.getWrapperOwners();

    this.onGetPlots();
  }

  getTotalOwned = async () => {
    return this.totalOwned || await this.contract.methods.totalOwned().call();
  }

  getActiveAccount = async () => {
    const accounts = await web3.eth.getAccounts();
    console.log("ACCOUNTS", accounts);
    return accounts[0];
  }

  formatPrice = (price) => {
    // We want to format the price so that we get up to 5 decimal places if needed
    // or if it's less than .000001 ETH, we display two sig figs
    const ethString = web3.utils.fromWei(price, 'ether');
    const ethNumber = Number(ethString);

    // TODO: Display in wei instead for very small values
    if (ethNumber && ethNumber < .001) {
      return ethNumber.toPrecision(2);
    } else if (ethString.length > 5) {
      return ethNumber.toFixed(5);
    } else {
      return ethNumber.toFixed(2);
    }
  }

  getPlot = async (id) => {
    if (id == null) {
      return null;
    }

    const existingPlot = this.plots[id];

    if (existingPlot && Date.now() - existingPlot.lastFetched < 5000) {
      return existingPlot;
    }

    const plot = await this.contract.methods.plots(id).call();
    
    // Add these attributes for convenience
    plot.id = id;
    plot.owned = plot.owner && plot.owner != "0x0000000000000000000000000000000000000000";
    plot.forSale = plot.forSale || !plot.owned;
    plot.priceInETH = plot.owned ? this.formatPrice(plot.price) : this.formatPrice(this.initialPrice);
    plot.lastFetched = Date.now();

    // Wrapper-owned plots are treated separately
    plot.isWrapped = plot.owner === WRAPPER_ADDRESS;
    if (plot.isWrapped) {
      const wrapperOwner = await this.getWrapperOwner(id);
      plot.owner = wrapperOwner|| WRAPPER_ADDRESS;
    }

    this.plots[id] = plot;

    return plot;
  }

  getPlotsForUser = async (account, onLoadPlot=null) => {
    try {
      // For this token, the balance is the number of deeds they have
      const numPlots = await this.contract.methods.balanceOf(account).call();
      const plotIDs = [];
      const plots = {};

      // First get all the plot IDs the user has
      for (let i = 0; i < numPlots; i++) {
        const plotID = await this.contract.methods
          .tokensOfOwnerByIndex(account, i)
          .call();
        plotIDs.push(plotID);
      }

      // Add any wrapped plots that are owned by this user
      Object.values(this.plots)
        .filter((plot) => plot.isWrapped && plot.owner === account)
        .forEach((plot) => plotIDs.push(plot.id));

      console.log('my plots', plotIDs);

      // Then get the actual plots
      for (let i = 0; i < plotIDs.length; i++) {
        const id = plotIDs[i];
        plots[id] = await this.getPlot(id);

        if (onLoadPlot) {
          onLoadPlot(plots[id]);
        }
      }

      // Cache the plots so they can be retrieved more easily later
      this.plots = Object.assign({}, this.plots, plots);

      return plots;
    } catch(e) {
      console.error(e);
      throw new EthereumError("Unable to get plots for user:", e.message);
    }
  }

  getInitialPrice = async () => {
    return await this.contract.methods.initialPrice.call();
  }

  getETHConversion = async (amountInETH, rounded=true) => {
    // Debounce to only re-fetch conversion after 5 seconds
    if (!this.usdConversion || Date.now() - this.conversionLastFetched > API_DEBOUNCE_MS) {
      const resp = await fetch(ETH_USD_API);
      const json = await resp.json();

      if (!json || !json.result) {
        throw Error("Unable to fetch current ETH conversion rate");
      }

      this.usdConversion = Number(json.result.ethusd);
      this.conversionLastFetched = Date.now();
    }

    let amountInUSD = Number(amountInETH) * this.usdConversion;
    const roundedUSD = parseFloat(Math.round(amountInUSD * 100)/100).toFixed(2);

    return rounded ? roundedUSD : amountInUSD;
  }

  purchase = async (id, metadata, forSale, newPriceInETH) => {
    try {
      const accounts = await web3.eth.getAccounts();
      const plot = await this.getPlot(id);
      const price = plot.owned ? plot.price : this.initialPrice;
      const purchaseable = (plot.forSale || !plot.owned) && !plot.disabled;

      if (!purchaseable) {
        throw new PurchaseError(`Plot ${id} is not available to purchase`);
      }

      const newPriceInWei = web3.utils.toWei(newPriceInETH, 'ether');

      console.log(plot, price, newPriceInETH, newPriceInWei)

      // Submit transaction to purchase
      return await this.contract.methods
        .purchase(id, metadata, forSale, newPriceInWei)
        .send({from: accounts[0], value: price, type: 0});
    } catch(e) {
      console.error("Error in purchase:", e);
      throw new PurchaseError(e.message);
    }
  }

  isValidAddress = (address) => {
    return web3.utils.isAddress(address);
  }

  transfer = async (id, to) => {
    try {
      const accounts = await web3.eth.getAccounts();
      const plot = await this.getPlot(id);

      if (plot.owner != accounts[0]) {
        throw new TransferError("You don't own this property. You may need to switch accounts");
      }

      const result = await this.contract.methods
        .transfer(id, to, "")
        .send({from: accounts[0], type: 0})
        .then(console.log);
      return result;
    } catch(e) {
      console.error("Error during transfer:", e);
      throw new TransferError(e.message);
    }
  }

  setPrice = async (id, newPriceInETH, forSale=true) => {
    try {
      const accounts = await web3.eth.getAccounts();
      const plot = await this.getPlot(id);

      if (plot.owner != accounts[0]) {
        throw new TransferError("You don't own this property. You may need to switch accounts");
      }

      const newPriceInWei = web3.utils.toWei(newPriceInETH, 'ether');

      const result = await this.contract.methods
        .setPrice(id, forSale, newPriceInWei)
        .send({from: accounts[0], type: 0})
        .then(console.log);
      return result;
    } catch(e) {
      console.error("Error during setPrice:", e);
      throw new EthereumError(e.message);
    }
  }

  setMetadata = async (id, metadata) => {
    if (typeof metadata != "string") {
      throw Error("Metadata should be a string");
    }

    try {
      const accounts = await web3.eth.getAccounts();
      const plot = await this.getPlot(id);

      if (plot.owner != accounts[0]) {
        throw new EthereumError("You don't own this property. You may need to switch accounts");
      }

      const result = await this.contract.methods
        .setMetadata(id, metadata)
        .send({from: accounts[0], type: 0})
        .then(console.log);
      return result;
    } catch(e) {
      console.error("Error during setMetadata:", e);
      throw new EthereumError(e.message);
    }
  }

  getWrapperOwner = async (i) => {
    try {
      const owner = await this.wrapper.methods.ownerOf(i).call();
      return owner;
    } catch (err) {
      return null;
    }
  }

  getWrapperOwners = async () => {
    let plotPromises = [];
    for (let i = 0; i < NUM_PLOTS; i++) {
      plotPromises.push(this.getWrapperOwner(i));
    }
    return Promise.all(plotPromises);
  }

  wrap = async (id) => {
    const account = await this.getActiveAccount();
    this.wrapper.methods.reserveNFT(id).send({from: account, gas: 200000, type: 0}).on("transactionHash", () => {
      this.contract.methods.transfer(id, WRAPPER_ADDRESS, "").send({from: account, gas: 200000, type: 0}).on("transactionHash", () => {
        this.wrapper.methods.mint(id).send({from: account, gas: 200000, type: 0});
      });
    })
  }

  unwrap = async (id) => {
    const account = await this.getActiveAccount();
    this.wrapper.methods.withdraw(id).send({from: account, type: 0});
  }
}
