import React, { Component } from 'react';
import {Link} from 'react-router-dom';

import {ProgressBar} from "./ProgressBar";

import {connectToEth} from "../contracts/LunarToken";

export class HomeView extends Component {
  componentDidMount() {
    if (this.props.moon) {
      console.log("hello moon")
      this.props.moon.disableInteraction();
    }
  }

  render() {
    return (
      <div>
        <h1 className="lunar__headline">Shoot for the Moon</h1>
        <h2 className="lunar__subheadline">Secure your spot in space today with a lunar land plot.</h2>
        <Link to="/profile" onClick={this.props.connect} className="lunar__cta">Explore the moon</Link>
        {this.props.loading || this.props.missingWeb3 ? (
          <p className="lunar__web3">
            This website requires web3.js.
            {' '}
            <a href='https://metamask.io'>Install Metamask here.</a>
          </p>
        ) : (
          <ProgressBar max={400} current={this.props.totalOwned} />
        )}
      </div>
    );
  }
}
