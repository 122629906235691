export const FAQContent = [{
    q: "How does it work?",
    a: "Lunartoken.co is a fully-functioning decentralized real estate exchange built on top of Ethereum smart contracts. Originally launched in 2017, this is one of the first non-fungible tokens (NFTs) ever created. For every moon plot you purchase, you receive a token called a “lunar” (symbol: <a target='_blank' href='https://etherscan.io/token/" + process.env.CONTRACT_ADDRESS + "'>LUNA</a>). Each token corresponds to a unique sector of the Moon, and has a price set by the owner upon purchase. Any other visitor to the moon can acquire a plot for the seller's price.",
  }, {
    q: "How many moon deeds are available?",
    a: "There are only 400 plots in existence, all of varying size, shape, and location. Even though all plots were claimed in late 2017, they remain available for purchase from the current owners and can be transferred or purchased at their current market prices.",
  }, {
    q: "How do I use this website?",
    a: "You first need to <a href='https://metamask.io/download.html'>install Metamask</a> and set it up with your Ethereum wallet. Then click on 'Explore the moon' and allow Metamask to connect to the site when prompted. Now you'll be able to explore lunar properties by clicking and dragging the 3D moon model. You can also view the plots of land that you currently own on <a href='/profile'>your profile</a>.", 
  }, {
    q: "Can I buy lunar plots on a NFT marketplace like OpenSea?",
    a: "Yes! There is an <a href='https://opensea.io/collection/wrapped-lunar' target='_blank'>official OpenSea collection here</a> that allows you to view and trade lunar plots. If you already have a plot, <a target='_blank' href='/wrapper/index.html'>visit this page to wrap or unwrap your lunar plot</a>.",
  }, {
    q: "Where can I learn more about this project?",
    a: "Visit us on <a href='https://discord.gg/4YQ4twNTUZ' target='blank'>Discord</a> and <a href='https://twitter.com/lunar_token/'>Twitter</a>. View the <a target='_blank' href='https://etherscan.io/address/" + process.env.REACT_APP_CONTRACT_ADDRESS + "'>contract on Etherscan</a>.", 
  }, {
    q: "How do I update the price of my plot of land?",
    a: "Once you've connected to the site with your Metamask wallet, you should see your properties listed on your <a href='/profile'>Profile page</a>, where you can update or transfer your property."
  }, {
    q: "How do I send or sell my deed to someone else?",
    a: "In order to transfer or sell your moon deed, you can use this site, the official <a href='https://opensea.io/collection/wrapped-lunar' target='_blank'>OpenSea collection</a>, or interact with the <a target='_blank' href='https://etherscan.io/address/" + process.env.REACT_APP_CONTRACT_ADDRESS + "'>contract</a> directly. Note: Sending with a traditional wallet won’t work because plots vary in location, size, and value—i.e. they are not fungible. Lunartoken was implemented using the <a href='https://github.com/ethereum/EIPs/issues/721' target='_blank'>original EIP proposal</a> for non-fungible tokens (NFTs), meaning you can still check your balance with a traditional ERC-20 wallet.",
  }, {
    q: "Is this for real?",
    a: "Yes! Lunar is a real functioning token and exchange. For all intents and purposes, you should treat your moon plot as a virtual trading card rather than a legally enforceable deed.",
  }, {
    q: "Is this legal?",
    a: "The <a href='https://en.wikipedia.org/wiki/Outer_Space_Treaty' target='_blank'>Outer Space Treaty</a> of 1967 clearly states, \"outer space, including the Moon and other celestial bodies, is not subject to national appropriation by claim of sovereignty, by means of use or occupation, or by any other means.\" In other words, no government can claim ownership of the moon. It says nothing, however, about individuals claiming ownership of the moon. Accordingly, Lunar provides a decentralized service to manage everyone’s claims.",
  }, {
    q: "Where did this come from?",
    a: "In the cryptocurrency community, people often use the expression, “We’re going to the moon!” But Lunar is the only token that will get you there.",
  }, {
    q: "What’s next?",
    a: "After colonizing the moon, we’ll begin exploring other planets and the solar systems. Or maybe we’ll sell Earth property instead!",
  }
];
