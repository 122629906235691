import React, { Component } from 'react';
import {Link} from 'react-router-dom';

import {MoonCard} from "./MoonCard";
import {MoonList} from './MoonList';

export const ArrowDown = () => (
  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 13l-5 5m0 0l-5-5m5 5V6" />
  </svg>
);

export class ProfileView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortPriceMin: true,
    };
  }

  componentDidMount() {
    if (this.props.moon) {
      this.props.moon.enableInteraction();
    }

    if (this.props.loadPlots) {
      this.props.loadPlots(this.props.user);
    }
  }

  componentDidUpdate() {
    if (this.props.loadPlots) {
      this.props.loadPlots(this.props.user);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.moon) {
      nextProps.moon.enableInteraction();
    }
  }

  onSort = () => {
    this.setState({sortPriceMin: !this.state.sortPriceMin});
  }

  getSortedPlots = () => {
    const plots = Object.values(this.props.plots)
      .filter(plot => plot.owner !== this.props.user && plot.forSale)
      .sort((a, b) => a.priceInETH - b.priceInETH);

    if (!this.state.sortPriceMin) plots.reverse();

    return plots;
  }

  render() {
    const {plots, user, isUser, missingWeb3, loading, pendingPurchases} = this.props;

    const moonCards = Object.values(plots)
      .filter(plot => plot.owner === user && user != null)
      .map(plot => (
        <Link className="lunar__profile__link" to={`/detail/${plot.id}`} key={"profile-link-" + plot.id}>
          <MoonCard
            id={plot.id} key={"moon-card-" + plot.id}
            title={"Plot " + plot.id}
            plot={plot}
            isOwner={isUser}
            description={null}
          />
        </Link>
      ));

    const pendingCards = Object.keys(pendingPurchases || {})
      .filter(id => plots[id] && plots[id].owner == user)
      .map(id => (
        <MoonCard
          id={id} key={"moon-card-" + id}
          title={"Plot " + id + " (Pending)"}
          plot={plots[id]}
          isOwner={true}
          description={null}
        />
      ));

    moonCards.concat(pendingCards);

    let onboardingMessage = missingWeb3 ? (
      <div>
        <img src="/images/ethereum.svg" width="50" className="lunar__missing-web3-logo" />
        <p>This website runs on Ethereum. Get yourself a Web3-compatible browser or
          the <a href="https://metamask.io/" target="_blank">Metamask extension</a> for Chrome.
        </p>
      </div>
    ) : (
      <div>
        <p>Welcome aboard!</p>
        <p>Get started by selecting a moon plot on the map to the right.</p>
      </div>
    );

    console.log(loading)

    let content = moonCards.length ? moonCards : (
        <div>
          <div className="lunar__profile__placeholder">
            {loading ? <div className="lunar__loader"></div> : onboardingMessage}
          </div>
        </div>
      );

    const allPlots = this.getSortedPlots();

    return (
      <div className="lunar__profile">
        <h3>Your Property</h3>
        {content}

        {!missingWeb3 && (
          <div className="lunar__profile-for-sale">
            <h3>Listings</h3>
            {allPlots.length > 0 ? (
              <div className="lunar__moon-list-container">
                <button
                  onClick={this.onSort}
                  className={this.state.sortPriceMin
                    ? "lunar__moon-list__button"
                    : "lunar__moon-list__button lunar__moon-list__button--reverse"
                }>
                  Price
                  <ArrowDown />
                </button>
                {allPlots && (
                  <div className="">
                    <MoonList plots={allPlots} />
                  </div>
                )}
              </div>
            ): (
              <div className="lunar__loader"></div>
            )}
          </div>
        )}
      </div>
    );
  }
}
