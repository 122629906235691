import React, { Component } from 'react';
import Modal from "react-modal";

import {FAQContent} from "../content/faq_content";

export class FAQModal extends Component {
  render() {
    return (
      <Modal
        isOpen={this.props.open}
        onRequestClose={this.props.onClose}
        contentLabel="FAQ"
        style={{
          content: {
            background: "black",
            color: "white",
            outline: 0,
            overflow: "scroll",
            maxHeight: "800px",
            margin: "0 auto",
          },
          overlay: {
            backgroundColor: "black"
          }
        }}
        className="lunar__modal"
      >
        <div className="lunar__faq">
          <h2 className="lunar__faq__title">Frequently Asked Questions</h2>
          <button className="lunar__faq__close" onClick={this.props.onClose}></button>
          {FAQContent.map((q, i) => {
            return (
              <div key={"question_" + i} className="lunar__faq__question">
                <h3>{q.q}</h3>
                <p dangerouslySetInnerHTML={{__html: q.a}} />
              </div>
            )
          })}
        </div>
      </Modal>
    );
  }
}
