import React, { Component } from 'react';
import Blockies from "react-blockies";
import {Link} from 'react-router-dom';

export class MoonList extends Component {
  render() {
    const {plots} = this.props;

    if (!plots || plots.length === 0) return null;

    const getPriceText = (plot) => plot.forSale
      ? `${plot.priceInETH} Ξ`
      : plot.isWrapped
        ? 'Wrapped'
        : 'NFS';

    return (
      <div className="lunar__moon-list">
        {plots.map(plot => (
          <Link to={`/detail/${plot.id}`}>
            <div className="lunar__moon-list-item">
              <span className="lunar__moon-list__title">
                Plot {plot.id}
              </span>
              <span className="lunar__moon-list__price">
                <Blockies seed={plot.owner} size={6} scale={4} />
                {getPriceText(plot)}
              </span>
            </div>
          </Link>
        ))}
      </div>
    );
  }
}