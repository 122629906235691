import React, { Component } from 'react';

export class TextInput extends Component {
  render() {
    const {name, label, type, suffix, rightAligned, ...otherProps} = this.props;
    const style = {
      textAlign: rightAligned ? "right" : "left",
      paddingRight: suffix ? 40 : 10,
    };

    return (
      <div className="lunar__input">
        <label htmlFor={name}>{label}</label>
        <input type={type || "text"}
          id={name}
          name={name}
          style={style}
          {...otherProps}
        />
        {suffix && <span className="lunar__input__suffix">{suffix}</span>}
      </div>
    );
  }
}
