import React, { Component } from 'react';
import {Link} from 'react-router-dom';

export class ListView extends Component {
  render() {
    const plots = new Array(400).fill(0)
      .map((x, i) => ({
        src: `https://wrapped-lunars.netlify.app/previews/${i}.png`,
      }));

    return (
      <div
        className="lunar__list"
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(25, 1fr)',
          zIndex: 10,
          background: 'black',
          width: '100%',
          gap: '8px',
        }}
      >
        {plots.map((p) => (
          <img src={p.src} width={40} height={40} key={p.src} />
        ))}
      </div>
    );
  }
}
