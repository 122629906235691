import React, { Component } from 'react';
import {Router, Route, Link} from 'react-router-dom';
import createBrowserHistory from 'history/createBrowserHistory'

import {WebGLMoon} from "./webgl/index";
import {LunarToken} from "./contracts/LunarToken";
import {HomeView} from "./components/HomeView";
import {ProfileView} from "./components/ProfileView";
import {DetailView} from "./components/DetailView";
import {ListView} from "./components/ListView";
import {FAQModal} from "./components/FAQModal";

import './App.css';

let lunarAPI;

const MAX_PLOTS = 400;

const history = createBrowserHistory();

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: "",
      plots: {},
      plotsForUser: {},
      formProps: {
        id: null,
        price: 0,
        priceInUSD: 0,
        purchaseSubmitted: false,
        purchaseComplete: false,
        address: null,
        validAddress: false,
        transferSubmitted: false,
      },
      pendingPurchases: {},
      pendingTransfers: {},
      loading: true,
      initialized: false,
      missingWeb3: false,
      error: null,
      loadingMoon: true,
      totalOwned: MAX_PLOTS,
      mobileBrowser: false,
      highQuality: false,
    };
  }

  async componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const highQuality = params.get('hq') === 'true';
    this.setState({highQuality});

    if (!this.isMobile()) {
      this.webGLMoon = new WebGLMoon(this.onSelectPlot, this.onCompleteLoading, highQuality);
    } else {
      this.setState({loadingMoon: false});
    }

    lunarAPI = new LunarToken(this.onLoadPlots);

    this.setState({enableConnection: lunarAPI.enableConnection});

    try {
      await lunarAPI.initialize();
      this.setState({
        user: await lunarAPI.getActiveAccount(),
        initialized: true,
        loading: false,
      });
    } catch (e) {
      this.setState({missingWeb3: true, loading: false});
      console.error(e);
    }
  }

  isMobile = () => window.navigator.userAgent && (window.navigator.userAgent.indexOf("Mobi") > -1);

  onUpdateForm = (e) => {
    const {name, value} = e.target;
    this.setState(state => {
      state.formProps[name] = value;
      return state;
    });

    if (name === "price") {
      lunarAPI.getETHConversion(value)
        .then(priceInUSD => {
          this.setState(state => {
            state.formProps.priceInUSD = priceInUSD;
            return state;
          });
        });
    } else if (name === "address") {
      this.setState(state => {
        state.formProps.validAddress = lunarAPI.isValidAddress(value);
        return state;
      });
    }
  }

  getPlot = (id) => {
    let plot = this.state.plots[id];
    if (plot && Date.now() - plot.lastFetched < 5000) {
      return plot;
    } else if (this.state.initialized) {
      lunarAPI.getPlot(id)
      .then(plot => {
        this.setState(state => {
          state.plots[id] = plot;
          const price = state.formProps.id == id ? state.formProps.price : plot.priceInETH;
          const metadata = state.formProps.id == id ? state.formProps.metadata : plot.metadata;
          state.formProps = Object.assign(
            {}, state.formProps, {
              id,
              price: price,
              priceInUSD: "",
              metadata: metadata,
              purchaseSubmitted: false,
              purchaseComplete: false,
              transferSubmitted: false,
            }
          );
          return state;
        });
        return plot;
      }).then(plot => {
        return lunarAPI.getETHConversion(plot.priceInETH);
      }).then(price => {
        this.setState(state => {
          state.formProps.priceInUSD = price;
          return state;
        });
      }).catch(e => {
        console.error(e);
      });
    }
    return plot ? plot : {loading: true};
  }

  getPlotsForUser = (id) => {
    const lastFetched = this.state.plotsForUser[id]
      ? this.state.plotsForUser[id].lastFetched
      : null;

    console.log(lastFetched);

    if (this.state.initialized && (!lastFetched || Date.now() - lastFetched > 5000)) {
      lunarAPI.getPlotsForUser(id)
        .then(plots => {
          this.setState(state => {
            state.loading = false;
            state.plots = Object.assign({}, this.state.plots, plots);
            state.plotsForUser[id] = plots;
            state.plotsForUser[id].lastFetched = Date.now();
            return state;
          });
        });
    }

    return this.state.plots;
  }

  onSubmitForm = async (e) => {
    const {id, price} = this.state.formProps;
    // let metadata = name && name.length ? `n:${name}` : '';
    this.setState(state => {
      state.formProps.purchaseSubmitted = true;
      state.pendingPurchases[id] = true;
      return state;
    });
    try {
      await lunarAPI.purchase(id, "", true, price);
      this.setState(state => {
        delete state.pendingPurchases[id];
        return state;
      });
      history.push({
        pathname: '/profile',
        state: {}
      });
    } catch (err) {
      // Just reset if it fails
      console.error(err);
      this.setState(state => {
        state.error = "Error" + e.message;
        state.formProps.purchaseSubmitted = false;
        return state;
      });
      history.push({
        pathname: '/detail/' + id,
        state: {}
      });
    }
  }

  onOpenModal = (e) => {
    this.setState({modalOpen: true});
  }

  onCloseModal = (e) => {
    this.setState({modalOpen: false});
  }

  onSelectPlot = async (id) => {
    // Update the selected plot
    this.setState({
      selectedPlot: id,
    });

    // Changing the location will automatically trigger
    // getPlot to be called for this ID
    const location = {
      pathname: '/detail/' + id,
      state: { selectedPlot: this.state.selectedPlot }
    }
    history.push(location);
  }

  onCompleteLoading = () => {
    this.setState({loadingMoon: false});
  }

  onLoadPlots = () => {
    console.log(lunarAPI.plots)
    this.setState({plots: lunarAPI.plots});
    const activePlots = Object.keys(lunarAPI.plots || {})
      .filter(plotID => lunarAPI.plots[plotID].owned);
    if (this.webGLMoon) {
      this.webGLMoon.setHighlightedPlots(activePlots);
    }
  }

  onTransfer = async (e) => {
    e.preventDefault();
    const {id, address} = this.state.formProps;

    this.setState(state => {
      state.formProps.transferSubmitted = true;
      return state;
    });
    try {
      await lunarAPI.transfer(id, address);
      this.setState(state => {
        state.pendingTransfers[id] = true;
        return state;
      });
    } catch(e) {
      this.setState(state => {
        state.error = "Error" + e.message;
        state.formProps.transferSubmitted = false;
        return state;
      });
      console.error(e);
      history.push({
        pathname: '/detail/' + id,
        state: {}
      });
    }
  }

  onSetPrice = async (e) => {
    e.preventDefault();
    const {id, price} = this.state.formProps;

    this.setState(state => {
      state.formProps.editSubmitted = true;
      return state;
    });
    try {
      await lunarAPI.setPrice(id, price);
    } catch(e) {
      this.setState(state => {
        state.error = "Error" + e.message;
        state.formProps.editSubmitted = false;
        return state;
      });
      console.error(e);
      history.push({
        pathname: '/detail/' + id,
        state: {}
      });
    }
  }

  onSetMetadata = async (e) => {
    e.preventDefault();
    const {id, metadata} = this.state.formProps;

    if (!metadata || metadata == "") {
      return;
    }

    this.setState(state => {
      state.formProps.metadataSubmitted = true;
      return state;
    });
    try {
      await lunarAPI.setMetadata(id, metadata);
    } catch(e) {
      this.setState(state => {
        state.error = "Error" + e.message;
        state.formProps.metadataSubmitted = false;
        return state;
      });
      console.error(e);
      history.push({
        pathname: '/detail/' + id,
        state: {}
      });
    }
  }

  onWrap = async (e) => {
    e.preventDefault();
    const {id} = this.state.formProps;
    
    try {
      await lunarAPI.wrap(id);
    } catch (e) {
      console.error('Error while wrapping', e);
    }
  }

  onUnwrap = async (e) => {
    e.preventDefault();
    const {id} = this.state.formProps;

    try {
      await lunarAPI.unwrap(id);
    } catch (e) {
      console.error('Error while unwrapping', e);
    }
  }
 
  render() {
    const {formProps, plots, user, loading, loadingMoon, missingWeb3, enableConnection} = this.state;

    return (
      <Router history={history}>
        <div className="lunar__container">
          <div className={loadingMoon ? "lunar__overlay lunar__overlay--loading" : "lunar__overlay"}>
            {loadingMoon && <div className="lunar__loader"></div>}
          </div>
          <video className="lunar__bg-video" playsInline autoPlay muted loop>
            <source src="/video/moon.mp4" type="video/mp4" />
          </video>
          <div className="lunar__hero">
            <div className="lunar__hero__left">
              <header className="lunar__header">
                <Link to="/">
                  <span className="lunar__logo">Lunartoken</span>
                </Link>
              </header>
              <Route path="/" exact={true} render={({match}) => (
                <HomeView
                  loading={loading}
                  missingWeb3={missingWeb3}
                  moon={this.webGLMoon}
                  totalOwned={this.state.totalOwned}
                  connect={enableConnection}
                />
              )}/>
              <Route path="/profile" render={() => (
                <ProfileView
                  user={user}
                  isUser={true}
                  onSelectPlot={this.onSelectPlot}
                  plots={this.state.plots}
                  loadPlots={this.getPlotsForUser}
                  allPlots={this.state.plots}
                  loading={loading}
                  missingWeb3={missingWeb3}
                  moon={!loadingMoon && this.webGLMoon}
                  pendingPurchases={this.state.pendingPurchases}
                />
              )}/>
              <Route path="/profile/:address" render={({match}) => (
                <ProfileView
                  user={match.params.address}
                  isUser={user == match.params.address}
                  onSelectPlot={this.onSelectPlot}
                  plots={this.getPlotsForUser(match.params.address)}
                  loading={loading}
                  missingWeb3={missingWeb3}
                  moon={!loadingMoon && this.webGLMoon}
                />
              )}/>
              <Route path="/detail/:plotID" render={({match}) => (
                <DetailView
                  {...formProps}
                  user={user}
                  onSubmit={this.onSubmitForm}
                  onUpdate={this.onUpdateForm}
                  onTransfer={this.onTransfer}
                  onSetPrice={this.onSetPrice}
                  onSetMetadata={this.onSetMetadata}
                  onWrap={this.onWrap}
                  onUnwrap={this.onUnwrap}

                  plot={this.getPlot(match.params.plotID)}
                  id={match.params.plotID}
                  pendingPurchases={this.state.pendingPurchases}
                  moon={!loadingMoon && this.webGLMoon}
                  connect={enableConnection}
                />
              )}/>
              <Route path="/list" render={({match}) => (
                <ListView
                  user={user}
                  moon={!loadingMoon && this.webGLMoon}
                  connect={enableConnection}
                />
              )}/>
            </div>
            <div id="webgl-container"></div>
          </div>
          <footer className="lunar__footer">
            <button className="lunar__info" onClick={this.onOpenModal}></button>
            <a href={"https://etherscan.io/address/" + process.env.REACT_APP_CONTRACT_ADDRESS} target="_blank">
              <img src="/images/built_with_ethereum.svg" alt="built with ethereum" />
            </a>
          </footer>
          <FAQModal open={this.state.modalOpen} onClose={this.onCloseModal} />
        </div>
      </Router>
    );
  }
}

export default App;
