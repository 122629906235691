import React, { Component } from 'react';
import Blockies from "react-blockies";

export class MoonCard extends Component {
  renderCardBody() {
    const {plot, isOwner} = this.props;

    if (plot.loading) {
      return (
        <div className="lunar__moon-card__body--loading">
          <div className="lunar__loader"></div>
        </div>
      );
    } else {
      let priceText = plot.forSale
        ? `Price ${plot.priceInETH} ETH`
        : plot.isWrapped
          ? 'Wrapped Plot'
          : 'Not for sale';
        
      const owner = plot.owner ? plot.owner.slice(0, 8) : null;
    
      return (
        <div className="lunar__moon-card__body">
          <span className="lunar__moon-card__price">
            {priceText}
          </span>
          {plot.owned ? (
            <div className="lunar__moon-card__owner">
              <Blockies seed={plot.owner} size={8} scale={4} />
              <div>
                <span>{isOwner ? 'Owned by you' : 'Owned by'}</span>
                <span className="lunar__moon-card__address">{owner}</span>
              </div>
            </div>
          ) : (
            <div className="lunar__moon-card__owner unowned">
              <img src="/images/person.svg" alt="" />
              <div>
                <span>Available for purchase</span>
              </div>
            </div>
          )}
        </div>
      );
    }
  }

  render() {
    const {plot, title, description} = this.props;

    if (!plot) {
      return null;
    }

    return (
      <div className={plot.loading ? "lunar__moon-card lunar__moon-card--loading" : "lunar__moon-card"}>
        <div className="lunar__moon-card__top">
          <span className="lunar__moon-card__title">{title}</span>
          {description && <p>{description}</p>}
        </div>
        {this.renderCardBody()}
      </div>
    );
  }
}
