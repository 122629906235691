const {Detector, Stats, THREE} = window;

// This is the approximate rotation needed to get the view
// of the moon from the earth's surface
const DEFAULT_Y_ROTATION = 3 * Math.PI / 2;

export class WebGLMoon {
    container;
    scene;
    renderer;
    camera;
    clock;
    controls;
    moon;
    wireframeMesh;
    rollOverMesh;
    rollOverMaterial;
    mouse;
    raycaster;
    starfield;
    light;
    rotationEnabled;
    selectionEnabled;

    constructor(onSelectPlot, onCompleteLoading, highQuality = false, sizeX=25, sizeY=16, wireframeVisible=false, interactionEnabled=false) {
        if (!Detector.webgl) {
            Detector.addGetWebGLMessage();
            return;
        }

        this.container = document.getElementById('webgl-container');

        // this.light = {
        //     speed: 0.1,
        //     distance: 1000,
        //     position: new THREE.Vector3(this.distance, 0, this.distance),
        //     orbit: function(center, rotation) {
        //         this.position.x = (center.x - this.distance);
        //         this.position.z = (center.z + this.distance);

        //         if (rotation) {
        //             this.rotation = rotation;
        //         }
        //     }
        // };

        this.highQuality = highQuality;
        this.wireframeVisible = wireframeVisible;
        this.sizeX = sizeX;
        this.sizeY = sizeY;

        this.highlightedPlots = [];

        // Event callbacks
        this.onSelectPlot = onSelectPlot ? onSelectPlot : () => {};
        this.onCompleteLoading = onCompleteLoading ? onCompleteLoading : () => {};

        // Event listeners
        window.addEventListener('load', this.onWindowLoaded, false);
        window.addEventListener('resize', this.onWindowResize, false);
        document.addEventListener('keydown', this.onDocumentKeyDown, false);
    }

    /** When the window loads, we immediately begin loading assets. While the
        assets loading Three.JS is initialized. When all assets finish loading
        they can be used to create objects in the scene and animation begins */
    onWindowLoaded = () => {
        this.loadAssets({
            paths: {
                moon: this.highQuality ? '/images/maps/moon_8k.jpg' : '/images/maps/moon.jpg',
                moonNormal: '/images/maps/moon_4k_normal_optimized.jpg',
                displacement: '/images/maps/moon_displacement.jpg',
            },
            onBegin: () => {},
            onProgress: (evt) => {},
            onComplete: (evt) => {
                var textures = evt.textures;
                this.moon = this.createMoon(textures.moon, textures.moonNormal, textures.displacement, this.sizeX, this.sizeY);
                this.animate();
                this.renderer.domElement.addEventListener("click", this.handleClick);

                if (this.onCompleteLoading) {
                    this.onCompleteLoading();
                }
            }
        });

        this.init();

        if (!this.interactionEnabled) {
            this.controls.enabled = false;
        }
    }

    enableInteraction = () => {
        this.interactionEnabled = true;
        this.wireframeLines.visible = true;
        this.hiddenMesh.visible = true;
        this.controls.enabled = true;
    }

    disableInteraction = () => {
        this.interactionEnabled = false;
        this.wireframeLines.visible = false;
        this.hiddenMesh.visible = false;
        this.controls.enabled = false;
    }

    createMoon = (textureMap, normalMap, displacement, sizeX, sizeY) => {
        var radius = 100;
        var xSegments = 200;
        var ySegments = 200;
        var geo = new THREE.SphereGeometry(radius, xSegments, ySegments);

        this.light = new THREE.PointLight(0xffffff, 1);
        this.light.position.set( -1000, 0, 1000 );
        this.scene.add( this.light );

        var mat = new THREE.MeshPhongMaterial( {
            specular: 0x333333,
            shininess: 4,
            map: textureMap,
            normalMap: normalMap,
            normalScale: new THREE.Vector2( .8, .8 ),
            displacementMap: this.highQuality ? displacement : undefined,
            displacementScale: 4,
            lights: true,
            polygonOffset: true,
            polygonOffsetFactor: 50, // positive value pushes polygon further away
            polygonOffsetUnits: 120
        } );

        var mesh = new THREE.Mesh(geo, mat);
        mesh.position.set(0, 0, 0);
        mesh.rotation.set(0, DEFAULT_Y_ROTATION, 0);
        this.createWireframeGeometry(mesh);
        mesh.name = "moon";

        this.scene.add(mesh);
        return mesh;
    }

    createWireframeGeometry = (mesh, rotation=DEFAULT_Y_ROTATION, highlightedPlots=[]) => {
        // Create wireframe geometry
        this.hiddenSphereGeo = new THREE.SphereGeometry(102, this.sizeX, this.sizeY);
        const materials = [
            new THREE.MeshBasicMaterial({transparent: true, opacity: 0}),
            new THREE.MeshBasicMaterial({transparent: true, opacity: 0, color: 0xffffff}),
            new THREE.MeshBasicMaterial({transparent: true, opacity: .7, color: 0xff0000}),
        ];
        for (var i = 0; i < this.hiddenSphereGeo.faces.length; i++) {
            this.hiddenSphereGeo.faces[i].materialIndex = 0;
        }

        this.hiddenMesh = new THREE.Mesh(this.hiddenSphereGeo, materials);
        this.hiddenMesh.position.set(0,0,0);
        this.hiddenMesh.rotation.set(0,rotation,0);
        this.hiddenMesh.name = "hidden_mesh";
        // this.scene.add(this.hiddenMesh);

        var edges = new THREE.EdgesGeometry(this.hiddenSphereGeo);
        var line = new THREE.LineSegments(edges, new THREE.LineBasicMaterial({
            color: 0xffffff,
            opacity: 0.15,
            transparent: true,
        }));
        line.name = 'wireframe_lines';
        this.wireframeLines = line;
        this.wireframeLines.visible = this.wireframeVisible;

        mesh.add(line);
    }

    setHighlightedPlots = (plotArray=[]) => {
        this.highlightedPlots = plotArray;
    }

    handleClick = (event) => {
        event.preventDefault();

        if (this.interactionEnabled) {
            this.onSelectPlot(this.activePlotID);
        }

        /* TODO: This is a potentially more robust way of finding
        the active face selected, and would not depend on face geometry */

        /*
        if (intersects.length > 0) {
            console.log("intersect found", intersects[0])
            var object = intersects[0];
            object.face.color = 0x000000;
            object.object.geometry.colorsNeedUpdate=true;

            var r = object.object.geometry.boundingSphere.radius;
            var x = object.point.x;
            var y = object.point.y;
            var z = object.point.z;
            console.log(r, x, y, z)

            var lat = 90 - (Math.acos(y / r)) * 180 / Math.PI;
            var lon = ((270 + (Math.atan2(x, z)) * 180 / Math.PI) % 360) - 180;

            lat = Math.round(lat * 100000) / 100000;
            lon = Math.round(lon * 100000) / 100000;
            console.log('lat='+lat+'&lon='+lon)
        }
        */
    }

    init = () => {
        this.renderer = new THREE.WebGLRenderer({
            antialias: true,
            preserveDrawingBuffer: true,
            alpha: true,
        });

        this.renderer.setClearColor(0x000000, 0);

        let minSize = Math.min(3*window.innerWidth/4, window.innerHeight);
        this.renderer.setSize(minSize, minSize);
        this.container.appendChild(this.renderer.domElement);

        var fov = 35;
        var aspect = minSize / minSize;
        var near = 1;
        var far = 65536;

        this.camera = new THREE.PerspectiveCamera(fov, aspect, near, far);
        this.camera.position.set(0, 0, 400);

        this.scene = new THREE.Scene();
        this.scene.add(this.camera);

        // Adapted from http://threejs.live/#/webgl_interactive_voxelpainter
        // var rollOverGeo = new THREE.BoxGeometry( 10, 10, 10 );
        // this.rollOverMaterial = new THREE.MeshBasicMaterial( { color: 0xff0000, opacity: 0.5, transparent: true } );
        // this.rollOverMesh = new THREE.Mesh( rollOverGeo, this.rollOverMaterial );
        // this.scene.add( this.rollOverMesh );

        this.raycaster = new THREE.Raycaster();
        this.mouse = new THREE.Vector2();

        this.container.addEventListener( 'mousemove', this.onDocumentMouseMove, false );

        this.controls = new THREE.TrackballControls(this.camera, this.container);
        this.controls.rotateSpeed = 0.3;
        this.controls.noZoom = true;
        this.controls.noPan = true;
        this.controls.dynamicDampingFactor = 0.4;

        this.stats = new Stats();
        this.stats.domElement.style.position = 'absolute';
        this.stats.domElement.style.bottom = '0px';

        this.clock = new THREE.Clock();
    }

    animate = () => {
        requestAnimationFrame(this.animate);

        if (!this.lightAngle){
            this.lightAngle = 2;
        } else {
            if (this.lightAngle > 0.5) {
                this.lightAngle = this.lightAngle - .01;
            }
        }

        // Set the light position to always be the camera position rotated by 1/2 radian
        this.light.position.set(
            this.camera.position.x * Math.cos(this.lightAngle) - Math.sin(this.lightAngle) * this.camera.position.z,
            this.camera.position.y,
            this.camera.position.x * Math.sin(this.lightAngle) + Math.cos(this.lightAngle) * this.camera.position.z,
        );


        this.controls.update(this.camera);

        this.moon.rotation.y = this.interactionEnabled ? this.moon.rotation.y : this.moon.rotation.y + .0005;
        this.hiddenMesh.rotation.y = this.moon.rotation.y;
        this.stats.update();
        this.renderer.render(this.scene, this.camera);
    }

    onDocumentKeyDown = (event) => {
        return;
    }

    onDocumentMouseMove = (event) => {
        if (!this.interactionEnabled) {
            return;
        }

        event.preventDefault();

        const rect = this.container.getBoundingClientRect();
        const x1 = rect.x;
        const x2 = rect.right;
        const y1 = rect.y;
        const y2 = rect.bottom;

        const mousePos = [
            ((event.clientX - x1) / rect.width ) * 2 - 1,
          - ((event.clientY - y1) / rect.height * 2) + 1
        ];
        this.mouse.set(mousePos[0], mousePos[1]);

        this.raycaster.setFromCamera( this.mouse, this.camera );

        var intersects = this.raycaster.intersectObjects([this.hiddenMesh]);

        if (intersects.length > 0) {

            var intersect = intersects[ 0 ];
            var faceIndex = intersect.faceIndex;

            if (faceIndex == null) {
                console.error("Unable to get face index");
                return;
            }

            if (faceIndex == this.activeFace) {
                // TODO: If already hovering on this face, don't do anything
                return;
            }

            this.scene.remove(this.hiddenMesh);
            const materials = [
                new THREE.MeshBasicMaterial({transparent: true, opacity: 0}),
                new THREE.MeshBasicMaterial({transparent: true, opacity: 0, color: 0xffffff}),
                new THREE.MeshBasicMaterial({transparent: true, opacity: .5, color: 0xff0000}),
            ];

            this.hiddenSphereGeo = new THREE.SphereGeometry(102, this.sizeX, this.sizeY);

            let highlightedFaces = [];
            this.highlightedPlots.forEach(plot => {
                let faces = this.getFacesFromPlotID(plot);
                if (faces[0]) faces[0].materialIndex = 1;
                if (faces[1]) faces[1].materialIndex = 1;
            });

            const activeFaces = this.getFacesFromIndex(faceIndex);
            activeFaces.forEach(face => {
                face.materialIndex = 2;
            });

            this.hiddenMesh = new THREE.Mesh(this.hiddenSphereGeo, materials);
            this.hiddenMesh.position.set(0,0,0);
            this.hiddenMesh.rotation.set(0,DEFAULT_Y_ROTATION,0);
            this.scene.add(this.hiddenMesh);

            // Set active plot so we can call onSelect with it on click
            this.activePlotID = this.getPlotIDFromFace(faceIndex);
        }
    }



    onWindowResize = () => {
        let minSize = Math.min(3*window.innerWidth/4, window.innerHeight);
        this.renderer.setSize(minSize, minSize);
        this.camera.aspect = minSize / minSize;
        this.camera.updateProjectionMatrix();
    }

    getPlotIDFromFace = (faceIndex) => {
        let plotID;
        const {sizeX: X, sizeY: Y} = this;

        // Number of triangular faces is 2*X + 2*X(Y-2) = 2(XY-1)
        const numPlots = X * Y;
        const numFaces = 2 * (X * Y - X);

        if (faceIndex < X) {
            // The first and last X faces are single triangles
            plotID = faceIndex;
        } else if (faceIndex >= numFaces - X) {
            plotID = faceIndex - (numFaces - numPlots);
        } else {
            plotID = Math.floor((faceIndex + X) / 2);
        }

        return plotID;
    }

    getFacesFromPlotID = (plotID) => {
        const {sizeX: X, sizeY: Y} = this;
        const numFaces = 2 * (X * Y - X);

        let faces = this.hiddenSphereGeo.faces;
        let activeFaces;

        if (plotID < X) {
            activeFaces = [faces[plotID]]
        } else if (plotID >= X*Y-X) {
            let remainder = plotID - (X*Y-X);
            activeFaces = [faces[numFaces - X + remainder]]
        } else {
            activeFaces = [faces[2 * plotID - X], faces[2 * plotID - X + 1]];
        }

        return activeFaces;
    }

    getFacesFromIndex = (faceIndex) => {
        const {sizeX: X, sizeY: Y} = this;
        const numFaces = 2 * (X * Y - X);

        var faces = this.hiddenSphereGeo.faces;
        let activeFaces;

        if (faceIndex < X || faceIndex >= numFaces - X) {
            activeFaces = [faces[faceIndex]];
        } else if ((faceIndex - X) % 2 != 0) {
            activeFaces = [faces[faceIndex], faces[faceIndex-1]];
        } else {
            activeFaces = [faces[faceIndex], faces[faceIndex+1]];
        }
        return activeFaces;
    }

    loadAssets = (options) => {
        var paths = options.paths;
        var onBegin = options.onBegin;
        var onComplete = options.onComplete;
        var onProgress = options.onProgress;
        var total = 0;
        var completed = 0;
        var textures = { };
        var key;

        const getOnLoad = (path, name) => {
            return (tex) => {
                console.log(path, name, tex)
                textures[name] = tex;
                textures[name].anisotropy = this.renderer.getMaxAnisotropy();
                completed++;
                if (typeof onProgress === 'function') {
                    onProgress({path, name, total, completed});
                }
                if (completed === total && typeof onComplete === 'function') {
                    onComplete({textures});
                }
            };
        }

        for (key in paths)
            if (paths.hasOwnProperty(key)) total++;

        onBegin({
            total: total,
            completed: completed
        });

        for (key in paths) {
            if (paths.hasOwnProperty(key)) {
                var path = paths[key];
                if (typeof path === 'string')
                    new THREE.TextureLoader().load(path, getOnLoad(path, key));
                else if (typeof path === 'object')
                    THREE.ImageUtils.loadTextureCube(path, null, getOnLoad(path, key));
            }
        }
    }
}
