import React, { Component } from 'react';

export class ProgressBar extends Component {
  render() {
    const {max, current} = this.props;

    let countString, percentFilled;
    if (current < max / 2) {
      countString = `${current} already claimed!`
      percentFilled = Math.min(current / max * 125, 100);
    } else if (max - current > 0) {
      countString = `Only ${max - current} left!`
      percentFilled = (current / max * 100);
    } else {
      countString = '100% claimed';
      percentFilled = 100;
    }

    return (
      <div className="lunar__progress-bar">
        <span>{countString}</span>
        <div className="lunar__progress-bar--outer">
          <div className="lunar__progress-bar--inner" style={{width: percentFilled + '%'}}></div>
        </div>
      </div>
    );
  }
}
